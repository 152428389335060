interface Product {
  img: any;
  name?: string;
  label: string;
  height?: string;
  width?: string
}
export enum ProductName {
  Design ='M-Design',
  Arch='M-Arch',
  ArchSim='M-ArchSim',
  DesignV2="M-DesignV2",
  DeSim="M-DeSim",
  InteG="M-InteG",
  Cowork="M-Cowork",
  DT='M-DT',
  EcoLink="M-EcoLink",
  PlugIn ="M-PlugIn",
  Require="M-Require",
  PM="M-PM",
  PLM="M-PLM",
  Simulation="M-Simulation",
  AI4SE="M-AI4SE",
  Reliability='M-Reliability',
  Doc="M-Doc",
  ReqTrace="M-ReqTrace"
}
export const productList = [{
  img: require('@/assets/img/product/m-arch.png'),
  name: ProductName.Arch,
  label: '体系建模软件'
}, {
  img: require('@/assets/img/product/m-archSim.png'),
  name: ProductName.ArchSim,
  label: '体系仿真软件'
}, {
  img: require('@/assets/img/product/m-design.png'),
  name: ProductName.Design,
  label: '系统建模软件'
}, {
  img: require('@/assets/img/product/m-designV2.png'),
  name: ProductName.DesignV2,
  label: '新一代系统建模与仿真软件'
}, {
  img: require('@/assets/img/product/m-deSim.png'),
  name: ProductName.DeSim,
  label: '系统仿真软件'
}, {
  img: require('@/assets/img/product/m-inteG.png'),
  name: ProductName.InteG,
  label: '多学科集成工具'
}, {
  img: require('@/assets/img/product/m-cowork.png'),
  name: ProductName.Cowork,
  label: '体系与系统协同建模工具'
}, {
  img: require('@/assets/img/product/m-dt.png'),
  name: ProductName.DT,
  label: "智能运维与数字孪生平台"
}, {
  img: require('@/assets/img/product/m-ecolink2.png'),
  name: ProductName.EcoLink,
  label: "工具链集成生态环境"
}, {
  img: require('@/assets/img/product/m-plugin.png'),
  name: ProductName.PlugIn,
  label: "插件开发平台和插件工具集"
}, {
  img: require('@/assets/img/product/m-require.png'),
  name: ProductName.Require,
  label: "需求管理软件"
}, {
  img: require('@/assets/img/product/m-pm.png'),
  name: ProductName.PM,
  label: "项目管理软件"
}, {
  img: require('@/assets/img/product/m-plm.png'),
  name: ProductName.PLM,
  label: "模型管理软件"
}];

export const slotList = [{
  img: require('@/assets/img/product/m-simulation.png'),
  name: ProductName.Simulation,
  label: "逻辑仿真验证插件"
}, {
  img: require('@/assets/img/product/m-ai4SE.png'),
  name: ProductName.AI4SE,
  label: "AI驱动MBSE插件"
}, {
  img: require('@/assets/img/product/m-reliability.png'),
  name: ProductName.Reliability,
  label: "可靠性分析设计插件"
}, {
  img: require('@/assets/img/product/m-doc.png'),
  name: ProductName.Doc,
  label: "自定义文档生成插件"
}, {
  img: require('@/assets/img/product/m-reqTrace.png'),
  name: ProductName.ReqTrace,
  label: "全生命周期需求变更追溯插件"
}];

export function listToRow(arr:Product[], count: number) {
  const result:Product[][] = [[]];
  let k = 0;
  for (let i = 0; i < arr.length; i ++) {
    if (result[k]) {
      result[k].push(arr[i]);
      if (result[k].length >= count) {
        k += 1;
      }
    } else {
      result[k] = [arr[i]];
    }
  }
  if (result[k] && result[k].length < count) {
    for (let i = result[k].length; i < count; i ++) {
      result[k].push({ img: '', name: '', label: '' });
    }
  }
  return result;
}

export const partners:Product[] = [
  { img: require('@/assets/img/index/c1.png'), label: '中国载人航天工程中心' },
  { img: require('@/assets/img/index/c2.png'), label: '国家航天局探月与航天<br/>工程中心' },
  { img: require('@/assets/img/index/c3.png'), label: '中国航天科技集团<br/>有限公司' },
  { img: require('@/assets/img/index/c4.png'), label: '中国航天科工集团<br/>有限公司' },
  { img: require('@/assets/img/index/c5.png'), label: '清华大学' },
  { img: require('@/assets/img/index/c6.png'), label: '中国广核集团<br/>有限公司' },
  { img: require('@/assets/img/index/c7.png'), label: '中国核工业集团<br/>有限公司' },
  { img: require('@/assets/img/index/c8.png'), label: '国家核电技术公司' },
  { img: require('@/assets/img/index/c9.png'), label: '中国电子科技集团<br/>有限公司' },
  { img: require('@/assets/img/index/c10.png'), label: '浙江大学' },
  { img: require('@/assets/img/index/c11.png'), label: '中国船舶集团<br/>有限公司' },
  { img: require('@/assets/img/index/c12.png'), label: '中国科学院' },
  { img: require('@/assets/img/index/c13.png'), label: '中国航空发动机集团<br/>有限公司' },
  { img: require('@/assets/img/index/c14.png'), label: '中国航空工业集团<br/>有限公司' },
  { img: require('@/assets/img/index/c15.png'), label: '北京航空航天大学' },
  { img: require('@/assets/img/index/c16.png'), label: '华为技术有限公司' },
  { img: require('@/assets/img/index/c17.png'), label: '上海电气新能源发展<br/>有限公司' },
  { img: require('@/assets/img/index/c18.png'), label: '中国铁道科学研究院集团<br/>有限公司' },
  { img: require('@/assets/img/index/c19.png'), label: '中国兵器工业集团<br/>有限公司' },
  { img: require('@/assets/img/index/c20.png'), label: '国防科技大学' },
  { img: require('@/assets/img/index/c21.png'), label: '中国兵器装备集团<br/>有限公司' },
  { img: require('@/assets/img/index/c22.png'), label: '中国卫星网络集团<br/>有限公司' },
  { img: require('@/assets/img/index/c23.png'), label: '中国东方电气集团<br/>有限公司' },
  { img: require('@/assets/img/index/c24.png'), label: '中国工程物理研究院' },
  { img: require('@/assets/img/index/c25.png'), label: '西北工业大学' }
];