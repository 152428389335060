
export function settingHtmlFontSizeForRem (baseSize = 50, baseWidth = 1920) {
  const doc = document;
  const docEI = doc.documentElement;
  if (isMobile()) {
    docEI.style.fontSize = '20px';
    return;
  }
  const win = window;
  const resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize';

  const resize = function () {
    const clientWidth = docEI.clientWidth;
    if (!clientWidth) return;
    let calcFontSize = baseSize * (clientWidth / baseWidth);
    calcFontSize = Math.min(calcFontSize, 50);
    calcFontSize = Math.max(36, calcFontSize);
    docEI.style.fontSize = calcFontSize + 'px';
  };
  const resizeDebounce = debounce(resize, 100);
  resize();
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, resizeDebounce, false);
  doc.addEventListener('DOMContentLoaded', resizeDebounce, false);
}

// 定义一个名为 isMobile 的函数，用于检测当前设备是否为移动设备
function isMobile() {
  // 获取用户代理字符串，用于识别浏览器和操作系统信息
  // navigator.userAgent 是标准的用户代理字符串
  // navigator.vendor 是浏览器的供应商信息
  // (window as any).opera 是针对 Opera 浏览器的特殊处理
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  // 使用正则表达式检测用户代理字符串中是否包含移动设备的关键词
  // 这些关键词包括各种移动设备和操作系统的标识
  return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|j2me|midp|mobile|opera mini|phone|palm|pocket|psp|symbian|up.browser|up.link|wap|windows ce|xda|xiino/i.test(
    userAgent
  );
}

/**
 * @param fn
 * @param delay
 * @returns
 */
export function debounce(fn: (...arg0: any[]) => void, delay = 300) {
  let pending: ReturnType<typeof setTimeout>;

  return function (this:any, ...args: any[]) {
    if (pending) {
      clearTimeout(pending);
    }
    const context = this;
    pending = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
