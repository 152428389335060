<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="isRouterAlive" />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style>
body {
  overflow: hidden scroll !important;
}
#app {
  font-family: PingFangSC-Regular, PingFang SC;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
/* ::-webkit-scrollbar {
  display: none;
} */
</style>
